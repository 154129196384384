import React from "react";
import "../styles/Footer.css";
import { ReactComponent as LoopxLogo } from "../assets/images/loopx.svg";
import InputGroup from "./InputGroup";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-logo-section">
        <LoopxLogo className="footer-logo-icon" />
        <div className="footer-copyright">
          © 2024 Niti Inc. All Rights Reserved.
        </div>
      </div>
      <div className="footer-socials-and-input-group">
        <div className="footer-socials">
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className="footer-social-icon" />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon className="footer-social-icon" />
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className="footer-social-icon" />
          </a>
        </div>
        <div className="footer-input-group-wrapper">
          <InputGroup />
        </div>
      </div>
    </div>
  );
};

export default Footer;
