import React from "react";
import leftArrow from "../assets/images/left-arrow.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import "../styles/PackSwitcher.css"; // Import the CSS file

interface PackSwitcherProps {
  title: string;
  subtitle: string;
  onPrevClick: () => void;
  onNextClick: () => void;
}

const PackSwitcher: React.FC<PackSwitcherProps> = ({
  title,
  subtitle,
  onPrevClick,
  onNextClick,
}) => {
  return (
    <div className="container">
      <div className="row">
        <button className="arrow" onClick={onPrevClick}>
          <img src={leftArrow} alt="Previous" className="arrowImage" />
        </button>
        <h1 className="title">{title}</h1>
        <button className="arrow" onClick={onNextClick}>
          <img src={rightArrow} alt="Next" className="arrowImage" />
        </button>
      </div>
      <h3 className="subtitle">{subtitle}</h3>
    </div>
  );
};

export default PackSwitcher;
