import React from "react";
import "../styles/AnnouncementBar.css";

const AnnouncementBar: React.FC = () => {
  const text = "React to your customer needs 10x faster with our starter packs.";

  return (
    <div className="announcement-bar">
      <div className="ticker-wrap">
        <div className="ticker">
          <div className="ticker__item">{text}</div>
          <div className="ticker__item">{text}</div> {/* Duplicate the text */}
          <div className="ticker__item">{text}</div> {/* Duplicate again */}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBar;
