import React, { useState } from "react";
import "../styles/Card.css";

interface CardProps {
  title: string;
  description: string;
  icon: string;
}

const Card: React.FC<CardProps> = ({ title, description, icon }) => {
  // @ts-ignore
  const [, setIsHovered] = useState(false);

  return (
    <div
      className={`outerCard`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`innerCard`}>
        <div className="textContainer">
          <h3 className="card-title">{title}</h3>
          <p className="description">{description}</p>
        </div>
        <div className="iconWrapper">
          <img src={icon} alt={`${title} icon`} className="icon" />
          <div className="bgOverlay" />
        </div>
      </div>
    </div>
  );
};

export default Card;
