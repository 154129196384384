import React from "react";
import "../styles/PricingCard.css";

interface PricingCardProps {
  badgeText?: string;
  title: string;
  description: string;
  price?: string;
  separator?: string;
  features: string[];
  buttonText: string;
  buttonLink: string;
  isContact?: boolean;
  imagePlaceholder?: string;
  customImage?: string;
  customImageAlt?: string;
  customText?: string;
  backgroundColor?: string;
  onRequestAccessClick?: () => void;
}

const PricingCard: React.FC<PricingCardProps> = ({
  badgeText,
  title,
  description,
  price,
  separator,
  features,
  buttonText,
  buttonLink,
  isContact = false,
  imagePlaceholder,
  customImage,
  customImageAlt,
  customText,
  backgroundColor = "#FF8736",
  onRequestAccessClick,
}) => {
  return (
    <div className="card-container" style={{ backgroundColor }}>
      <div className="header-container">
        <div className={`badge ${!badgeText ? "invisible-badge" : ""}`}>
          {badgeText || "Placeholder"}
        </div>
        <h3 className="pricing-title">{title}</h3>
        <p className="pricing-description">{description}</p>
      </div>
      <div className="content-container">
        {!isContact && price && (
          <div className="price-container">
            <h2 className="price">{price}</h2>
          </div>
        )}
        {customText && (
          <div className="custom-row-container">
            {customImage && (
              <img
                src={customImage}
                alt={customImageAlt}
                className="custom-image"
              />
            )}
            <h3 className="custom-text">{customText}</h3>
          </div>
        )}
        {separator && (
          <div className="separator-container">
            <div className="separator">{separator}</div>
          </div>
        )}
        <ul className="feature-list">
          {features.map((feature, index) => (
            <li key={index} className="feature-item">
              {imagePlaceholder && (
                <img
                  src={imagePlaceholder}
                  alt="Checkmark"
                  className="check-icon"
                />
              )}
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="button-container">
        {onRequestAccessClick ? (
          <button onClick={onRequestAccessClick} className="button">
            {buttonText}
          </button>
        ) : (
          <a href={buttonLink} className="button">
            {buttonText}
          </a>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
