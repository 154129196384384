import React from "react";
import "../styles/HeroSection.css";
import xImage from "../assets/images/x.svg";
import InputGroup from "./InputGroup";
import FlipWords from "./FlipWords";

const HeroSection: React.FC = () => {
  const words = ["speed", "personalization", "empowerment"];

  return (
    <section className="hero-section-parent">
      <div className="hero-section">
        <div className="hero-content">
          <h1>
            The gift of{" "}
            <FlipWords words={words} className="highlight" duration={2000} />
          </h1>
          <h2>for modern software teams.</h2>
          <p>
            Prototype, ship and iterate product experiences without Dev or
            DevOps using our AI agents and co-pilots.
          </p>
          <InputGroup />
        </div>
        <div className="hero-image">
          <img src={xImage} alt="X" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
