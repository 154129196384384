import React from "react";
import "../styles/TabComponent.css";

interface Tab {
  label: string;
  icon: string;
}

interface TabComponentProps {
  tabs: Tab[];
  activeTab: string;
  onTabClick: (tab: string) => void;
}

const TabComponent: React.FC<TabComponentProps> = ({
  tabs,
  activeTab,
  onTabClick,
}) => {
  const tabCount = tabs.length;
  const gridColumns = tabCount === 4 ? 1 : 2;

  return (
    <div
      className={`tab-container ${tabCount === 4 ? "single-column" : "two-columns"}`}
      style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
    >
      {tabs.map(({ label, icon }) => (
        <button
          key={label}
          className={`tab ${activeTab === label ? "active" : ""}`}
          onClick={() => onTabClick(label)}
        >
          <img
            src={icon}
            alt="icon"
            className="tab-icon"
          />
          {label}
        </button>
      ))}
    </div>
  );
};

export default TabComponent;
