import React from "react";
import "../styles/Header.css";
import loopxLogo from "../assets/images/loopx.svg";

interface HeaderProps {
  onRequestAccessClick: () => void;
  onPricingClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onRequestAccessClick, onPricingClick }) => {
  return (
    <header className="header">
      <div className="logo">
        <img src={loopxLogo} alt="LOOPX Logo" />
      </div>
      <nav>
        <ul>
          <li>
            <div className="nav-link nav-home" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              Home
            </div>
          </li>
          <li>
            <div className="nav-link nav-pricing" onClick={onPricingClick}>
              Pricing
            </div>
          </li>
          <li>
            <div className="nav-link" onClick={onRequestAccessClick}>
              Request Access
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
