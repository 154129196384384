// Icons
import carouselIcon from "../assets/icons/announcement.svg";
import announcementIcon from "../assets/icons/announcement.svg";
import popupIcon from "../assets/icons/popup.svg";
import scratchCardIcon from "../assets/icons/scratchCard.svg";
import spinWheelIcon from "../assets/icons/spinWheel.svg";
import storiesIcon from "../assets/icons/stories.svg";
import streakIcon from "../assets/icons/popup.svg";
import bannersIcon from "../assets/icons/banners.svg";

// Growth Pack Images
import growthCarousel from "../assets/images/new_packs/growth_carousel.svg";
import growthCarouselMobile from "../assets/images/new_packs/growth_carousel_phone.svg";
import growthPopup from "../assets/images/new_packs/growth_popup.svg";
import growthPopupMobile from "../assets/images/new_packs/growth_popup_phone.svg";
import growthBanner from "../assets/images/new_packs/growth_banner.svg";
import growthBannerMobile from "../assets/images/new_packs/growth_banner_phone.svg";
import growthAnnouncement from "../assets/images/new_packs/growth_announcement.svg";
import growthAnnouncementMobile from "../assets/images/new_packs/growth_announcement_phone.svg";
import growthStreak from "../assets/images/new_packs/growth_streak.svg";
import growthStreakMobile from "../assets/images/new_packs/growth_streak_phone.svg";
import growthSpinWheel from "../assets/images/new_packs/growth_spin_wheel.svg";
import growthSpinWheelMobile from "../assets/images/new_packs/growth_spin_wheel_phone.svg";
import growthScratchCard from "../assets/images/new_packs/growth_scratch_card.svg";
import growthScratchCardMobile from "../assets/images/new_packs/growth_scratch_card_phone.svg";
import growthStories from "../assets/images/new_packs/growth_stories.svg";
import growthStoriesMobile from "../assets/images/new_packs/growth_stories_phone.svg";


// Product Pack Images
import productUserFeedback from "../assets/images/new_packs/product_user_feedback.svg";
import productUserFeedbackMobile from "../assets/images/new_packs/product_user_feedback_mobile.svg";
import productSurvey from "../assets/images/new_packs/product_survey.svg";
import productSurveyMobile from "../assets/images/new_packs/product_survey_mobile.svg";
import productPrototyping from "../assets/images/new_packs/product_prototyping.svg";
import productPrototypingMobile from "../assets/images/new_packs/product_prototyping_mobile.svg";
import productOnboarding from "../assets/images/new_packs/product_onboarding.svg";
import productOnboardingMobile from "../assets/images/new_packs/product_onboarding_mobile.svg";

// Exporting assets for easy import in other files
export const growthPack = {
  title: "Growth Pack",
  subtitle:
    "Empower your growth teams to improve frequency, depth and breadth of product usage",
  tabs: [
    { label: "Pop up", icon: popupIcon },
    { label: "Carousel", icon: carouselIcon },
    { label: "Story", icon: storiesIcon },
    { label: "Announcement", icon: announcementIcon },
    { label: "Streak", icon: streakIcon },
    { label: "Banner", icon: bannersIcon },
    { label: "Spin a wheel", icon: spinWheelIcon },
    { label: "Scratch card", icon: scratchCardIcon },
  ],
  images: {
    Carousel: { desktop: growthCarousel, mobile: growthCarouselMobile },
    "Pop up": { desktop: growthPopup, mobile: growthPopupMobile },
    Story: { desktop: growthStories, mobile: growthStoriesMobile },
    Banner: { desktop: growthBanner, mobile: growthBannerMobile },
    Announcement: {
      desktop: growthAnnouncement,
      mobile: growthAnnouncementMobile,
    },
    Streak: { desktop: growthStreak, mobile: growthStreakMobile },
    "Spin a wheel": { desktop: growthSpinWheel, mobile: growthSpinWheelMobile },
    "Scratch card": {
      desktop: growthScratchCard,
      mobile: growthScratchCardMobile,
    },
  },
};

export const productPack = {
  title: "Product Pack",
  subtitle:
    "Empower your product teams to capture customer feedback, prototype and improve user onboarding",
  tabs: [
    { label: "User Feedback", icon: carouselIcon },
    { label: "Survey", icon: popupIcon },
    { label: "Prototyping", icon: storiesIcon },
    { label: "Onboarding", icon: bannersIcon },
  ],
  images: {
    "User Feedback": {
      desktop: productUserFeedback,
      mobile: productUserFeedbackMobile,
    },
    Survey: { desktop: productSurvey, mobile: productSurveyMobile },
    Prototyping: {
      desktop: productPrototyping,
      mobile: productPrototypingMobile,
    },
    Onboarding: {
      desktop: productOnboarding,
      mobile: productOnboardingMobile,
    },
  },
};
