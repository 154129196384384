import { useMediaQuery } from "@mui/material";
import journeyMobile from "../assets/images/platform_journey_mobile.svg";
import journeyDesktop from "../assets/images/platform_journey_desktop.svg";

const JourneySection = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div
      style={{
        width: "100%",
        minHeight: "85vh", // Takes up 75% of the viewport height
        backgroundColor: "#2c2c2c",
        padding: isMobile ? "20px 10px" : "60px 20px", // Adjust padding to prevent overflow
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box", // Ensure padding is included in the height calculation
      }}
    >
      <h2
        style={{
          textAlign: "center",
          color: "white",
          margin: isMobile ? "10px" : "20px 0",
          fontSize: isMobile ? "1.4rem" : "2.2rem",
          lineHeight: isMobile ? "1.6rem" : "2.6rem",
          fontWeight: "bold",
          maxWidth: "800px",
        }}
      >
        Prompt-first and drag-drop No Code platform to bring any idea to life
      </h2>
      <p
        style={{
          textAlign: "center",
          color: "white",
          marginBottom: isMobile ? "30px" : "30px",
          fontSize: isMobile ? "0.9rem" : "1.2rem",
          lineHeight: isMobile ? "1.4rem" : "1.8rem",
          maxWidth: "700px",
        }}
      >
        Build frontend, backend, data workflows and launch it on our app runtime
      </p>
      <img
        src={isMobile ? journeyMobile : journeyDesktop}
        alt="Journey"
        style={{
          width: isMobile ? "70%" : "70%",
          height: "auto",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default JourneySection;
