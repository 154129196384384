import React, { useEffect, useRef, useState } from "react";
import "./styles/App.css";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AnnouncementBar from "./components/AnnouncementBar";
import PackSection from "./components/PackSection";
import CardGrid from "./components/CardGrid";
import PricingSection from "./components/PricingSection";
import Footer from "./components/Footer";
import Popup from "./components/Popup";
import JourneySection from "./components/JourneySection";


const App: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const pricingRef = useRef<HTMLDivElement>(null);

  const handleScrollToPricing = () => {
    pricingRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.vectorPixelId = "7d269e42-b0de-46a9-af5b-f3448e93854a";
    const script = document.createElement("script");
    script.src = "https://cdn.vector.co/pixel.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      <Header
        onRequestAccessClick={() => setIsPopupOpen(true)}
        onPricingClick={handleScrollToPricing}
      />
      <HeroSection />
      <JourneySection/>
      <AnnouncementBar />
      <PackSection />
      <CardGrid />
      <div ref={pricingRef}>
        <h1 className="pricing-header">Pricing</h1>
        <PricingSection onRequestAccessClick={() => setIsPopupOpen(true)} />
      </div>
      <Footer />
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </div>
  );
};

export default App;
