import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styles/Popup.css";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (email) {
      sendEmail(email); // Send email using EmailJS
      sendSlackAlert(email); // Send alert to Slack
      setIsSubmitted(true);
    }
  };

  const sendEmail = (email: string) => {
    const templateParams = {
      from_email: email,
    };

    emailjs
      .send(
        "service_p620i7s",
        "template_c0ftmii",
        templateParams,
        "NdBAxuNOmXN0USicb"
      )
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
      });
  };

  const sendSlackAlert = (email: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append(
      "payload",
      JSON.stringify({
        username: "loopx-bot",
        text: `New email submission: ${email}`,
        icon_emoji: ":email:",
      })
    );

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow" as RequestRedirect, // Explicitly casting to RequestRedirect
    };

    fetch(
      "https://hooks.slack.com/services/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",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log("Slack Alert Sent:", result))
      .catch((error) => console.error("Error Sending Slack Alert:", error));
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="popup-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="popup-content-wrapper">
          {!isSubmitted ? (
            <>
              <h2 className="popup-title">Request Access</h2>
              <p className="popup-message">
                If you want to talk to us sooner, write to us at diy@loopx.live
              </p>
              <div className="popup-input-group-wrapper">
                <input
                  type="email"
                  placeholder="Please enter your email address"
                  value={email}
                  onChange={handleInputChange}
                  className="popup-input"
                />
                <button className="popup-button" onClick={handleSubmit}>
                  Request Access
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className="popup-title">Thanks for your interest.</h2>
              <p className="popup-message">
                Give us a couple of days to get back to you by email.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
