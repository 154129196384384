import React from "react";
import "../styles/CardGrid.css";
import Card from "./Card";
import lightningIcon from "../assets/images/lightning.svg";
import coinIcon from "../assets/images/coin.svg";
import starsIcon from "../assets/images/stars.svg";
import sdkIcon from "../assets/images/sdk.svg";
import puzzleIcon from "../assets/images/puzzle.svg";
import piechartIcon from "../assets/images/piechart.svg";

const CardGrid: React.FC = () => {
  return (
    <div className="gridContainer">
      <div className="grid">
        <Card
          title="Avoid long request queue"
          description="Pick from our ready to launch templates that shorten your development cycle"
          icon={lightningIcon}
        />
        <Card
          title="Customize infinitely"
          description="Customise any frontend or backend workflows to suit your branding and business logic"
          icon={coinIcon}
        />
        <Card
          title="Build fast with AI"
          description="Create and ship instantly with Gen AI co-pilots & agents, and without dev or devops"
          icon={starsIcon}
        />
        <Card
          title="Embed once, ship forever"
          description="Embed our paper-thin SDK once and ship forever with no app updates"
          icon={sdkIcon}
        />
        <Card
          title="Personalize everything"
          description="Reduce churn, maximize revenue with targeted experiences to chosen user cohorts"
          icon={puzzleIcon}
        />
        <Card
          title="Make data-driven choices"
          description="Built-in analytics for every journey to optimize conversions and power A/B testing"
          icon={piechartIcon}
        />
      </div>
    </div>
  );
};

export default CardGrid;
