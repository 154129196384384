import React from "react";
import PricingCard from "./PricingCard";
import "../styles/PricingSection.css";
import TickIcon from "../assets/icons/tick.svg";
// import PhoneIcon from "../assets/icons/phone.svg";

interface PricingSectionProps {
  onRequestAccessClick: () => void;
}

const PricingSection: React.FC<PricingSectionProps> = ({
  onRequestAccessClick,
}) => {
  return (
    <div className="section-container">
      <PricingCard
        badgeText="MOST POPULAR"
        title="DIY Model"
        description="For teams who can self-service the platform to get started"
        // customImage={PhoneIcon}
        customImageAlt="Phone Icon"
        customText="Talk to us"        separator="Everything on the platform"
        features={[
          "Upto 100,000 MAU",
          "Support up to 1 app",
          "Platform access to up to 5 users",
        ]}
        buttonText="Request Access"
        buttonLink="#" // This can be ignored if you're triggering a popup
        imagePlaceholder={TickIcon}
        backgroundColor="#FF8736"
        onRequestAccessClick={onRequestAccessClick} // Pass the popup handler
      />
      <PricingCard
        title="Enterprise"
        description="For teams with high volume, security, control, or support needs."
        // customImage={PhoneIcon}
        customImageAlt="Phone Icon"
        customText="Talk to us"
        separator="Everything in DIY, plus"
        features={[
          "More than 100,000 MAU",
          "On prem or private hosting",
          "Dedicated support engineer",
        ]}
        buttonText="Contact Sales"
        buttonLink="https://calendly.com/teamniti"
        imagePlaceholder={TickIcon}
        backgroundColor="rgba(140, 141, 144, 0.2)"
      />
    </div>
  );
};

export default PricingSection;
